@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');

@font-face {
  font-family: hero-light;
  src: url("./fonts/Hero-Light.woff2") format("woff2");
}

.glowbFont {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Shrikhand', cursive;
  }
}

.glowbHeroFont {
  font-family: hero-light, sans-serif;
}