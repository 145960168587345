// Your variable overrides
$body-bg: white;
// $body-color: #222;
$primary: #FE91B0;
// Bootstrap and its default variables
@import "./_variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/_progress.scss";
@import "./_bootswatch.scss";

body {
  color: #212121;
  font-family: 'Poppins', "Helvetica Neue", "Calibri Light", Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
}

.nav-item{
  white-space: nowrap;
}
.nav-tabs::-webkit-scrollbar {
  display: none; /*Safari and Chrome*/
}

.glowbBgPink {
  background-color: #FCC7D1
}

.glowbPink {
  color: #FCC7D1
}

.glowbLightBgPink {
  background-color: #FDE7E9 ;
}

.glowbLightPink {
  color: #FDE7E9 ;
}

.glowbDarkBgPink {
  background-color: #FC97BF;
}

.glowbDarkPink {
  color: #FC97BF
}

.glowbBgPink2 {
  background-color: #FCD4D5;
}

.glowbBgPink3 {
  background-color: #FAB3B7;
}

.glowbPink3 {
  color: #FAB3B7
}

.glowbSteps {
  padding-left: 15px!important;
  padding-right: 15px!important;
}

.placeholder-change::placeholder {
    color: black!important;
    font-size: x-large!important;
}

.word-break{
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.clickableRow {
  cursor: pointer;
}
.clickableRow:hover {
  opacity: 0.8;
}

//media queries for responsive images on glowb landing page
.fontSmall {
  font-size: x-small;
}

@include media-breakpoint-up(sm) {
  .glowbFooterMargin {
    margin-left: 2.5rem!important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .h-md-85 {
    height: 85% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .fontLarge {
    font-size: large;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-65 {
    width: 65%!important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .fontSmall {
    font-size: small;
  }
}

@include media-breakpoint-down(sm) {
  .testimonial-bg {
    background-color: #FDE7EA;
  }
}